import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_alert = _resolveComponent("el-alert");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_alert, {
    title: "Learn more about Myneral's application to your specific industry",
    type: "info",
    description: "Simply drag your finger or hold [⇧Shift]` and scroll your mouse on the map below to zoom in. Click on the highlighted components to learn more about myneral's application to your specific industry!",
    "show-icon": ""
  })]);
}